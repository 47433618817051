<script lang="ts" setup>
  import { BaseButton, MediaCard } from '@bt/design-system'
  import type ICard from '@/types/Storyblok/General/Card'
  import { useBreakpoints } from '~/composables/useBreakpoints'

  const { locale, t } = useI18n()
  const { isMobile, isTablet } = useBreakpoints()

  const { blok } = defineProps<{
    blok: ICard
  }>()

  const openLink = (slug: string) => {
    if (slug.story) {
      return formatSbPath(slug.story.full_slug, locale.value)
    }

    if (slug.url) {
      return formatSbPath(slug.url, locale.value)
    }
  }

  const getModifiers = computed(() => {
    const data = {}

    if (blok.image?.focus) {
      Object.assign(data, { filters: { focal: blok.image?.focus } })
    }

    return data
  })
</script>

<template>
  <MediaCard
    :text="blok.title"
    class="media-card"
  >
    <template #image="{ className }">
      <NuxtImg
        v-if="isMobile || isTablet"
        :alt="blok.image.alt ?? ''"
        :class="className"
        :modifiers="getModifiers"
        :src="blok.mobileImage?.filename || blok.image.filename"
        :title="blok.image.title ?? ''"
        :width="isMobile ? 328 : 348"
        height="188"
        provider="storyblok"
      />
      <NuxtImg
        v-else
        :alt="blok.image.alt ?? ''"
        :class="className"
        :modifiers="getModifiers"
        :src="blok.image.filename"
        :title="blok.image.title ?? ''"
        height="348"
        provider="storyblok"
        width="262"
      />
    </template>
    <template #action>
      <NuxtLink :to="openLink(blok.slug)">
        <BaseButton
          :title="$t('buttons.read-more')"
          color="white"
          type="outlined"
        />
      </NuxtLink>
    </template>
  </MediaCard>
</template>

<style lang="scss" scoped>
  @import '@/assets/styles/common.scss';

  .media-card {
    margin: 0 auto;
    width: 100%;

    img {
      height: 100%;
    }
  }
</style>
